import FacebookIcon from './images/fb.svg';
import InstagramIcon from './images/ig.svg';
import LinkedinIcon from './images/linkedin.svg';

export const NAV_HEIGHT = 108;
export const NAV_HEIGHT_MOBILE = 68;

export const NAV_LINKS = [
  { name: 'Wiki', url: '/insurance-types' },
  { name: 'Our Story', url: '/our-story' },
  { name: 'Blog', url: '/blog' },
  { name: 'Market Scan', url: '/market-scan' },
  { name: 'FAQs', url: '/faqs' },
];

export const SOCIAL_MEDIA = [
  // { Icon: TwitterIcon, url: "https://twitter.com/getquashed" },
  { Icon: LinkedinIcon, url: 'https://www.linkedin.com/company/quashed' },
  { Icon: FacebookIcon, url: 'https://facebook.com/getquashed' },
  { Icon: InstagramIcon, url: 'https://instagram.com/getquashed' },
];

export const HAMBURGER_LINKS = [{ name: 'Home', url: '/' }, ...NAV_LINKS];

export const ANIMATION_LENGTH = 2000;

export const INSURANCE_TYPE_OTHER = 'Other';

export const INSURANCE_TYPES_NAMES = {
  VEHICLE: 'Vehicle',
  HOME_CONTENTS: 'House & Contents',
  CONTENT: 'Contents',
  HOME: 'House',
  RENTERS: 'Renters',
  PET: 'Pet',
  LANDLORD: 'Landlord',
  MOTORCYCLE: 'Motorcycle',
  TRAVEL: 'Travel',
  BOAT: 'Boat',
  HEALTH: 'Health',
  LIFE: 'Life',
  MBI: 'MBI',
  TRAUMA: 'Trauma',
  INCOME: 'Income Protection',
  MORTGAGE: 'Mortgage Repayment',
  REDUNDANCY: 'Redundancy',
  DISABILITY: 'TPD',
  CANCER: 'Cancer',
  CAR: 'Car',
};

export const INSURANCE_TYPES_WITHOUT_WIKI = [
  INSURANCE_TYPES_NAMES.HOME_CONTENTS,
  INSURANCE_TYPES_NAMES.RENTERS,
  INSURANCE_TYPES_NAMES.TRAVEL,
  INSURANCE_TYPES_NAMES.LANDLORD,
];

export const INSURANCE_TYPES_LIFE = [
  INSURANCE_TYPES_NAMES.LIFE,
  INSURANCE_TYPES_NAMES.HEALTH,
  INSURANCE_TYPES_NAMES.TRAUMA,
  INSURANCE_TYPES_NAMES.INCOME,
  INSURANCE_TYPES_NAMES.MORTGAGE,
  INSURANCE_TYPES_NAMES.REDUNDANCY,
  INSURANCE_TYPES_NAMES.DISABILITY,
];

export const INSURANCE_TYPE_CAR = {
  VEHICLE: 'Car',
};

export const INSURANCE_TYPES_KEYS = Object.keys(INSURANCE_TYPES_NAMES).map(
  (k) => INSURANCE_TYPES_NAMES[k],
);

const FOOTER_INSURANCE_TYPES_NAMES = {
  ...INSURANCE_TYPES_NAMES,
  ...INSURANCE_TYPE_CAR,
};
export const FOOTER_INSURANCE_TYPES_KEYS = Object.keys(
  FOOTER_INSURANCE_TYPES_NAMES,
)
  .map((k) => FOOTER_INSURANCE_TYPES_NAMES[k])
  .filter((k) => !INSURANCE_TYPES_WITHOUT_WIKI.includes(k));

export const REFERRAL_ID_STORAGE_KEY = 'referralId';
export const Q4E_STORAGE_KEY = 'q4eId';
export const MARKET_SCAN_PLUS_STORAGE_KEY = 'marketScanPlus';
export const UTM_SOURCE_STORAGE_KEY = 'utm_source';
export const UTM_CAMPAIGN_STORAGE_KEY = 'utm_campaign';
export const UTM_MEDIUM_STORAGE_KEY = 'utm_medium';
export const UTM_CONTENT_STORAGE_KEY = 'utm_content';

export const INSURANCE_TYPES_NAMES_ALIAS = {
  [INSURANCE_TYPES_NAMES.CANCER]: ['Cancer cover'],
  [INSURANCE_TYPES_NAMES.VEHICLE]: ['Car'],
  [INSURANCE_TYPES_NAMES.HOME]: ['Home'],
  [INSURANCE_TYPES_NAMES.HOME_CONTENTS]: [
    'Home and Contents',
    'House and Contents',
  ],
  [INSURANCE_TYPES_NAMES.DISABILITY]: [
    'Total permanent disablement',
    'Total disablement cover',
    'Total and Permanent disability',
  ],
  [INSURANCE_TYPES_NAMES.MORTGAGE]: [
    'Mortgage protection',
    'Mortgage repayment and Household expenses',
  ],
};

export const INSURANCE_TYPES_LABELS = {
  [INSURANCE_TYPES_NAMES.DISABILITY]: 'Total Permanent Disability',
  [INSURANCE_TYPES_NAMES.MBI]: 'Mechanical Breakdown',
};

export const PARTNER_DROPDOWN = [
  { label: 'Quashed for Advisers', link: '/quashed-for-advisers' },
  { label: 'Quashed for Employers', link: '/quashed-for-employers' },
];

export const Q4A_ONBOARDING_URL = '/quashed-for-advisers/get-started';

export const Q4E_ONBOARDING_URL = `mailto:team@quashed.co.nz?subject=Quashed for Employers&body=Hey there,%0D%0A%0D%0AI’d love to know more about Quashed for Employers. What can you tell me about it?`;
