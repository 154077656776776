module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"fZ19oUtpbEQDJAG2rCe6seOvFjXEQuaf","trackPage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Quashed","short_name":"Quashed","start_url":"/","background_color":"#0e1e24","theme_color":"#0e1e24","display":"standalone","icon":"src/images/quashed-fav-icon.png","icons":[{"src":"icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"icons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"icons/icon-128x128.png","sizes":"128x128","type":"image/png","purpose":"maskable"},{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"icons/icon-152x152.png","sizes":"152x152","type":"image/png","purpose":"maskable"},{"src":"icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"icons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6dc279dab09993f22f2ed9a727bbdab3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
