import { format } from 'date-fns';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  NAV_HEIGHT,
  NAV_HEIGHT_MOBILE,
  Q4E_STORAGE_KEY,
  REFERRAL_ID_STORAGE_KEY,
  PARTNER_DROPDOWN,
  UTM_SOURCE_STORAGE_KEY,
  UTM_CAMPAIGN_STORAGE_KEY,
  UTM_MEDIUM_STORAGE_KEY,
  UTM_CONTENT_STORAGE_KEY,
} from '../constants';

// Remove special characters except dash
const removeSpecialCharacters = (str) => {
  return str.replace(/[`~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/gi, '');
};

export const getFaqsFromQuery = (edges) => {
  if (edges && edges.length) {
    return edges.map(
      ({ node: { title, description, order, showOnHomePage } }) => {
        return {
          title,
          content: description.content[0].content[0].value,
          order,
          showOnHomePage,
        };
      },
    );
  }
  return [];
};

export const getRichTextFromQuery = (edges, options) => {
  if (edges && edges.length) {
    return edges.map(({ node }) => {
      const { description, image, ...attr } = node;
      return {
        ...attr,
        image: (image && image.file && image.file.url) || null,
        content: documentToReactComponents(
          JSON.parse(description.raw),
          options,
        ),
      };
    });
  }
  return [];
};

export const getWikiFromQuery = (edges, filter) => {
  if (edges && edges.length) {
    const edge = edges.find(({ node }) => node.title === filter);
    if (edge) {
      const { questionTitles } = edge.node;
      return questionTitles.map((title, order) => {
        const content = edge.node[`questionDesc${order + 1}`];
        return {
          title,
          content: documentToReactComponents(JSON.parse(content.raw)),
          order,
        };
      });
    }
  }
  return [];
};

export const getWikiDescFromNode = (node) => {
  return node.questionTitles.map((title, order) => {
    const content = node[`questionDesc${order + 1}`];
    if (content) {
      return {
        title,
        content: documentToReactComponents(JSON.parse(content.raw)),
        order,
      };
    }
  });
};

export const getUrlRoot = (pathname) => {
  return pathname.includes('/v2') ? '/v2' : '/';
};

export const getParameterByName = (param, url) => {
  if (typeof window === 'undefined') return null;
  const name = param.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url || window.location.href);
  return results
    ? decodeURIComponent(results[2]?.replace(/\+/g, ' ') ?? '')
    : null;
};

export const scrollToId = (id) => {
  if (typeof window !== 'undefined') {
    const el = document.getElementById(id);
    const y =
      el.getBoundingClientRect().top +
      window.pageYOffset -
      (window.innerWidth > 768 ? NAV_HEIGHT : NAV_HEIGHT_MOBILE);
    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

// Replace fn ln with dataOne and dataTwo
// And encrypt by base64
const getNewFormData = (form) => {
  const firstName = removeSpecialCharacters(form.firstName);
  const lastName = removeSpecialCharacters(form.lastName);

  delete form.firstName;
  delete form.lastName;

  form.fn = btoa(encodeURIComponent(firstName.trim()));
  form.ln = btoa(encodeURIComponent(lastName.trim()));

  return form;
};

const objToQueryString = (obj) => {
  const formKeys = Object.keys(obj);
  return formKeys
    .reduce((acc, key) => {
      if (obj[key]) {
        acc.push(`${key}=${encodeURIComponent(obj[key])}`);
      }
      return acc;
    }, [])
    .join('&');
};

const getFormQuery = (form) => {
  const newForm = getNewFormData(form);
  return objToQueryString(newForm);
};

export const getRedirectUrl = (form, app_url, queries) => {
  const externalId = getAnonymousId();

  const query = objToQueryString({
    ...form,
    prompt: 'signup',
    q4eId: localStorage.getItem(Q4E_STORAGE_KEY),
    referralId: localStorage.getItem(REFERRAL_ID_STORAGE_KEY),
    utmSource: localStorage.getItem(UTM_SOURCE_STORAGE_KEY),
    utmCampaign: localStorage.getItem(UTM_CAMPAIGN_STORAGE_KEY),
    utmMedium: localStorage.getItem(UTM_MEDIUM_STORAGE_KEY),
    utmContent: localStorage.getItem(UTM_CONTENT_STORAGE_KEY),
    externalId,
  });

  let url = `${app_url}/onboarding?${query}`;

  const otherQueries = getSearchQuery();
  if (otherQueries) {
    url = `${url}&${otherQueries}`;
  }

  if (queries) {
    url = `${url}&${queries}`;
  }

  return url;
};

export const getAdviserRedirectUrl = (form, app_url) => {
  const query = getFormQuery(form);
  return `${app_url}/onboarding?prompt=signup&adviserSignUp=true&${query}`;
};

export const getMarketScanPlusRedirectUrl = (app_url) => {
  return `${app_url}/onboarding?prompt=signup&marketScanPlus=true`;
};

export const getInsuranceSlug = (title) =>
  `${title.replace(/ /g, '-').toLowerCase()}-insurance`;

export const formatDateBlog = (dateStr) =>
  format(new Date(dateStr), 'dd MMMM yyyy');

export const getPathName = () => {
  if (typeof window !== 'undefined') {
    return window.location.pathname;
  }
  return '';
};

export const getSearchQuery = () => {
  if (typeof window !== 'undefined') {
    return window.location.search.replace('?', '');
  }
  return '';
};

export const getHash = () => {
  if (typeof window !== 'undefined') {
    return window.location.hash;
  }
  return '';
};

export const getPartnerDropdownByVersion = (basePath) => {
  return PARTNER_DROPDOWN.map((p) => ({
    ...p,
    link: basePath ? `${basePath}${p.link}` : p.link,
  }));
};

export const segmentTrackEvent = (eventName, data) => {
  if (
    typeof window !== 'undefined' &&
    window.analytics &&
    window.analytics.user
  ) {
    const anonymousId = window.analytics.user().anonymousId();
    window.analytics.track(eventName, {
      category: 'Marketing',
      externalId: anonymousId,
      external_id: anonymousId,
      deviceType: getDeviceType(),
      ...data,
    });
  }
};

export const debounce = (cb, delay, timeoutId) => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
  return setTimeout(cb, delay);
};

const isRichTextEmpty = (json) => {
  return (
    json.content.length === 1 &&
    json.content[0].content.length === 1 &&
    json.content[0].content[0].value &&
    json.content[0].content[0].value.trim() === ''
  );
};

export const getBlogSections = (node) => {
  const sections = [];
  // Loop up to 10 sections
  for (let i = 1; i <= 15; i++) {
    const val = node[`sectionContent${i}`];
    if (val && !isRichTextEmpty(JSON.parse(val.raw))) {
      const layout = node[`sectionLayout${i}`];
      const data = {
        raw: JSON.parse(val.raw),
        layout,
      };
      if (val.references) {
        data['references'] = val.references;
      }
      sections.push(data);
    }
  }
  return sections;
};

export const mergeState = (obj) => (prevState) => ({
  ...prevState,
  ...obj,
});

export const getAnonymousId = () => {
  if (
    typeof window !== 'undefined' &&
    window.analytics &&
    window.analytics.user
  ) {
    return window.analytics.user().anonymousId();
  }
  return '';
};

export const getDeviceType = () =>
  window?.innerWidth > 820 ? 'Desktop' : 'Mobile';
